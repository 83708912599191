/* eslint-disable */
import {
    Calendar,
    MessageSquare,
    Users,
    PieChart,
    Package,
    PhoneCall,
    List,
    CheckCircle,
    XCircle,
    LogOut,
    HelpCircle,
    User,
} from "react-feather";

const asideMenus = [
    {
        id: -1,
        label: "Session",
        url: "/",
        Icon: Package,
        submenu: [
            {
                id: -11,
                label: "Profilo",
                url: "/app/profilo",
                Icon: User,
            },
            {
                id: -12,
                label: "Ristorante",
                url: "#!app/ristorante",
                Icon: Users,
            },
            {
                id: -13,
                label: "Help Center",
                url: "/app/help-center",
                Icon: HelpCircle,
            },
            {
                id: -14,
                label: "Esci",
                url: "#!app/messaggi",
                Icon: LogOut,
            },
        ],
    },
    {
        id: 1,
        label: "Generale",
        url: "/",
        Icon: Package,
        submenu: [
            {
                id: 11,
                label: "Calendario",
                url: "/app/calendario",
                Icon: Calendar,
            },
            {
                id: 13,
                label: "Clientela",
                url: "/app/clientela",
                Icon: Users,
            },
            {
                id: 14,
                label: "Statistiche",
                url: "/app/statistiche",
                Icon: PieChart,
            },
            {
                id: 15,
                label: "Messaggi",
                url: "#!app/messaggi",
                Icon: MessageSquare,
            },
            {
                id: 16,
                label: "Centralino",
                url: "#!app/centralino",
                Icon: PhoneCall,
            },
        ],
    },
    {
        id: 2,
        label: "Prenotazioni",
        url: "/",
        Icon: Calendar,
        submenu: [
            {
                id: 21,
                label: "Lista Prenotazioni",
                url: "/app/prenotazioni/lista",
                Icon: List,
            },
            {
                id: 22,
                label: "Storico Accettate",
                url: "/app/prenotazioni/accettate",
                Icon: CheckCircle,
            },
            {
                id: 23,
                label: "Storico Rifutate",
                url: "/app/prenotazioni/rifiutate",
                Icon: XCircle,
            },
        ],
    },
];

export default asideMenus;
