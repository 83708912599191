/* eslint-disable */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import { ICategoryOLD } from "@doar/shared/types";

// Define a type for the slice state

export interface CategoryState {
    categorySources: ICategoryOLD[];
}

// // Define the initial state using that type
const initialState: CategoryState = {
    categorySources: [],
};

interface ICategoryState {
    id: string;
    title: string;
    description?: string;
}

const categorySlice = createSlice({
    name: "categories",
    initialState,
    reducers: {
        createCategory: {
            reducer: (state, action: PayloadAction<ICategoryState>) => {
                const {
                    payload: { ...data },
                } = action;
                const source = state.categorySources.at(0);

                source?.categories.push(data);
            },
            prepare: (
                title: string,
                description?: string
            ) => {
                const data = {
                    id: uuidv4(),
                    title,
                    description,
                };
                return { payload: { ...data } };
            },
        },
        editCategory: {
            reducer: (state, action: PayloadAction<ICategoryState>) => {
                const {
                    payload: { ...data },
                } = action;
                const source = state.categorySources.at(0);
                if (source) {
                    const index = source.categories.findIndex(
                        (el) => el.id === data.id
                    );
                    if (index !== undefined && index >= 0) {
                        source.categories[index] = data;
                    }
                }
            },
            prepare: (
                id,
                title: string,
                description?: string
            ) => {
                const data = {
                    id,
                    title,
                    description,
                };
                return { payload: { ...data } };
            },
        },
        deleteCategory: (
            state,
            action: PayloadAction<{ id: string }>
        ) => {
            const {
                payload: { id },
            } = action;
            const source = state.categorySources.at(0);
            if (source) {
                const index = source.categories.findIndex((el) => el.id === id);
                if (index !== undefined && index >= 0) {
                    source.categories.splice(index, 1);
                }
            }
        },
    },
});

export const { createCategory, editCategory, deleteCategory } = categorySlice.actions;

export default categorySlice.reducer;
