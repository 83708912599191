/* eslint-disable */

import { Suspense, lazy } from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Outlet,
} from "react-router-dom";
import Preloader from "./components/preloader";
import Layout from "./layouts";
import Layout02 from "./layouts/layout-02";

// Prenota-Azione! Pages

const PrenotaAzioneDashboardOne = lazy(() => import("./pages/prenota-azione/dashboard-one"));
const PrenotaAzioneDashboardHome = lazy(() => import("./pages/prenota-azione/dashboard-home"));

const PrenotaAzioneBookingList = lazy(() => import("./pages/prenota-azione/app/bookings"));
// const PrenotaAzioneBookingAccept = lazy(() => import("./pages/prenota-azione/app/bookings-accept"));
// const PrenotaAzioneBookingReject = lazy(() => import("./pages/prenota-azione/app/bookings-reject"));

const PrenotaAzioneCustomers = lazy(() => import("./pages/prenota-azione/customers"));
const PrenotaAzioneCalendar = lazy(() => import("./pages/prenota-azione/app/calendar"));
const PrenotaAzioneStatistics = lazy(() => import("./pages/prenota-azione/stats"));
const PrenotaAzioneFileManager = lazy(() => import("./pages/prenota-azione/app/file-manager"));
const PrenotaAzioneMail = lazy(() => import("./pages/prenota-azione/app/mail"));
const PrenotaAzioneErrorNotFound = lazy(() => import("./pages/prenota-azione/error-404"));

// Classic Plus Pages

const ClassicPlusDashboardOne = lazy(() => import("./pages/classic-plus/dashboard-one"));
const ClassicPlusDashboardTwo = lazy(() => import("./pages/classic-plus/dashboard-two"));
const ClassicPlusDashboardThree = lazy(() => import("./pages/classic-plus/dashboard-three"));
const ClassicPlusDashboardFour = lazy(() => import("./pages/classic-plus/dashboard-four"));
const ClassicPlusDashboardFive = lazy(() => import("./pages/classic-plus/dashboard-five"));
const ClassicPlusSignIn = lazy(() => import("./pages/classic-plus/signin"));
const ClassicPlusSignUp = lazy(() => import("./pages/classic-plus/signup"));
const ClassicPlusVerifyAccount = lazy(() => import("./pages/classic-plus/verify-account"));
const ClassicPlusForgotPassword = lazy(() => import("./pages/classic-plus/forgot-password"));
const ClassicPlusErrorNotFound = lazy(() => import("./pages/classic-plus/error-404"));
const ClassicPlusError500 = lazy(() => import("./pages/classic-plus/error-500"));
const ClassicPlusError503 = lazy(() => import("./pages/classic-plus/error-503"));
const ClassicPlusError505 = lazy(() => import("./pages/classic-plus/error-505"));
const ClassicPlusProfileView = lazy(() => import("./pages/classic-plus/profile-view"));
const ClassicPlusConnections = lazy(() => import("./pages/classic-plus/connections"));
const ClassicPlusGroups = lazy(() => import("./pages/classic-plus/groups"));
const ClassicPlusEvents = lazy(() => import("./pages/classic-plus/events"));
const ClassicPlusTimeline = lazy(() => import("./pages/classic-plus/timeline"));
const ClassicPlusPricing = lazy(() => import("./pages/classic-plus/pricing"));
const ClassicPlusHelpCenter = lazy(() => import("./pages/classic-plus/help-center"));
const ClassicPlusInvoice = lazy(() => import("./pages/classic-plus/invoice"));
const ClassicPlusCalendar = lazy(() => import("./pages/classic-plus/apps/calendar"));
const ClassicPlusChat = lazy(() => import("./pages/classic-plus/apps/chat"));
const ClassicPlusContacts = lazy(() => import("./pages/classic-plus/apps/contacts"));
const ClassicPlusFileManager = lazy(() => import("./pages/classic-plus/apps/file-manager"));
const ClassicPlusMail = lazy(() => import("./pages/classic-plus/apps/mail"));

const App = () => {
    return (
        <>
            <Router>
                <Suspense fallback={<Preloader />}>
                    <Routes>

                        {/* Prenota Azione Routes */}

                        <Route
                            element={
                                <Layout02>
                                    <Outlet />
                                </Layout02>
                            }
                        >
                            {/* Dashboard Routes */}
                            <Route
                                path="/"
                                element={<PrenotaAzioneDashboardHome />}
                            />
                        </Route>

                        {/* Apps Routes */}

                        {/* App Route With Minimize Sidebar*/}
                        <Route
                            element={
                                <Layout02 aside="minimize">
                                    <Outlet />
                                </Layout02>
                            }
                        >
                            <Route
                                path="/app/calendario"
                                element={<PrenotaAzioneCalendar />}
                            />
                            <Route
                                path="/app/statistiche"
                                element={<PrenotaAzioneStatistics />}
                            />
                            <Route
                                path="/app/messaggi"
                                element={<PrenotaAzioneFileManager />}
                            />
                            <Route
                                path="/app/centralino"
                                element={<PrenotaAzioneMail />}
                            />
                            <Route
                                path="/app/clientela"
                                element={<PrenotaAzioneCustomers />}
                            />
                            <Route
                                path="/app/prenotazioni/lista"
                                element={<PrenotaAzioneBookingList />}
                            />
                            <Route
                                path="/app/prenotazioni/rifiutate"
                                element={<PrenotaAzioneBookingList />}
                            />
                            <Route
                                path="/app/prenotazioni/accettate"
                                element={<PrenotaAzioneBookingList />}
                            />
                            <Route
                                path="/app/profilo"
                                element={<ClassicPlusProfileView />}
                            />
                            <Route
                                path="/app/help-center"
                                element={<ClassicPlusHelpCenter/>}
                            />
                        </Route>

                        {/* Classic Plus Routes */}

                        <Route
                            element={
                                <Layout02>
                                    <Outlet />
                                </Layout02>
                            }
                        >
                            {/* Dashboard Routes */}
                            <Route
                                path="/classic-plus/dashboard-one"
                                element={<ClassicPlusDashboardOne />}
                            />
                            <Route
                                path="/classic-plus/dashboard-two"
                                element={<ClassicPlusDashboardTwo />}
                            />
                            <Route
                                path="/classic-plus/dashboard-three"
                                element={<ClassicPlusDashboardThree />}
                            />
                            <Route
                                path="/classic-plus/dashboard-four"
                                element={<ClassicPlusDashboardFour />}
                            />
                            <Route
                                path="/classic-plus/dashboard-five"
                                element={<ClassicPlusDashboardFive />}
                            />

                            {/* Authentication Routes */}
                            <Route
                                path="/classic-plus/signin"
                                element={<ClassicPlusSignIn />}
                            />
                            <Route
                                path="/classic-plus/signup"
                                element={<ClassicPlusSignUp />}
                            />
                            <Route
                                path="/classic-plus/verify-account"
                                element={<ClassicPlusVerifyAccount />}
                            />
                            <Route
                                path="/classic-plus/forgot-password"
                                element={<ClassicPlusForgotPassword />}
                            />

                            {/* Error Routes */}
                            <Route
                                path="/classic-plus/error-500"
                                element={<ClassicPlusError500 />}
                            />
                            <Route
                                path="/classic-plus/error-503"
                                element={<ClassicPlusError503 />}
                            />
                            <Route
                                path="/classic-plus/error-505"
                                element={<ClassicPlusError505 />}
                            />
                            <Route
                                path="/classic-plus/error-404"
                                element={<ClassicPlusErrorNotFound />}
                            />

                            {/* User Routes */}
                            <Route
                                path="/classic-plus/profile-view"
                                element={<ClassicPlusProfileView />}
                            />
                            <Route
                                path="/classic-plus/connections"
                                element={<ClassicPlusConnections />}
                            />
                            <Route
                                path="/classic-plus/groups"
                                element={<ClassicPlusGroups />}
                            />
                            <Route
                                path="/classic-plus/events"
                                element={<ClassicPlusEvents />}
                            />

                            {/* Other Routes */}
                            <Route
                                path="/classic-plus/timeline"
                                element={<ClassicPlusTimeline />}
                            />
                            <Route
                                path="/classic-plus/pricing"
                                element={<ClassicPlusPricing />}
                            />
                            <Route
                                path="/classic-plus/help-center"
                                element={<ClassicPlusHelpCenter />}
                            />
                            <Route
                                path="/classic-plus/invoice"
                                element={<ClassicPlusInvoice />}
                            />
                        </Route>

                        {/* Apps Routes */}

                        {/* App Route With Minimize Sidebar*/}
                        <Route
                            element={
                                <Layout02 aside="minimize">
                                    <Outlet />
                                </Layout02>
                            }
                        >
                            <Route
                                path="/classic-plus/apps/calendar"
                                element={<ClassicPlusCalendar />}
                            />
                            <Route
                                path="/classic-plus/apps/file-manager"
                                element={<ClassicPlusFileManager />}
                            />
                            <Route
                                path="/classic-plus/apps/mail"
                                element={<ClassicPlusMail />}
                            />
                        </Route>

                        {/* App Route With Sidebar Layout 2*/}
                        <Route
                            element={
                                <Layout02 aside="minimize">
                                    <Outlet />
                                </Layout02>
                            }
                        >
                            <Route
                                path="/classic-plus/apps/chat"
                                element={<ClassicPlusChat />}
                            />
                            <Route
                                path="/classic-plus/apps/contacts"
                                element={<ClassicPlusContacts />}
                            />
                        </Route>

                        {/* 404 Page Route */}
                        <Route
                            element={
                                <Layout02>
                                    <Outlet />
                                </Layout02>
                            }
                        >
                            <Route path="*" element={<PrenotaAzioneErrorNotFound />} />
                        </Route>
                    </Routes>
                </Suspense>
            </Router>
        </>
    );
};

export default App;
