/* eslint-disable */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define a type for the slice state
export interface TableState {
    tableSources: Array<string>;
}

// Define the initial state using that type
const initialState: TableState = {
    tableSources: [],
};

const tableSlice = createSlice({
    name: "tables",
    initialState,
    reducers: {
        createTable: {
            reducer: (state, action: PayloadAction<string>) => {
                const id = action.payload;
                state.tableSources.push(id);
            },
            prepare: (id: string) => ({ payload: id }),
        },
        deleteTable: (state, action: PayloadAction<string>) => {
            const id = action.payload;
            const index = state.tableSources.findIndex(tableId => tableId === id);
            if (index !== -1) {
                state.tableSources.splice(index, 1);
            }
        },
        deleteAllTables: (state) => {
            state.tableSources = [];
        },
    },
});

export const { createTable, deleteTable, deleteAllTables} = tableSlice.actions;

export default tableSlice.reducer;
